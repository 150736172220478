// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { UserContext } from '../providers/UserProvider'; // 'global' user object
import { putSingleRecord } from '../components/utils/dataFunctions';

// eslint-disable-next-line
import SEO from '../components/SEO';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    color: 'hsl(0, 0%, 16%) !important',
  },
}));

function PricingButton(props) {
  const { variant, color, children, bridgeId } = props;
  const contextObject = useContext(UserContext);
  const { firebaseToken } = contextObject;

  async function clickMe() {
    console.log('clicked.');

    const costingObject = { dummy: 'yes' };

    const costingDetails = await putSingleRecord(
      'costings',
      bridgeId,
      costingObject,
      firebaseToken,
    );
    console.log('costingDetails:', costingDetails);
  }

  return (
    <Button variant={variant} color={color} onClick={clickMe}>
      {children} Bridge ID: {bridgeId}
    </Button>
  );
}

function Home() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <SEO title="Home" />
      <Paper className={classes.root} elevation={2}>
        <Typography variant="h5" component="h1" color="inherit">
          Test Page
        </Typography>
        <Typography variant="body1" color="inherit">
          Click button to test price calculation:
        </Typography>
        <PricingButton
          variant="contained"
          color="primary"
          bridgeId="FU5crwCmteU8Z0DhWsy7"
        >
          Update Costing Info For
        </PricingButton>
        <PricingButton
          variant="contained"
          color="primary"
          bridgeId="pxXQ2yh1K5FPE9GeKgtb"
        >
          Update Costing Info For
        </PricingButton>
      </Paper>
    </div>
  );
}
export default Home;
